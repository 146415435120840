<template>
  <div>
    <b-card no-body class="mb-0">
      <!-- title="Gestion des Ressources Humaines" -->
      <b-tabs>
        <b-tab active>
          <template #title>
            <feather-icon icon="HomeIcon" />
            <span>Gestion du Personnel</span>
          </template>

          <b-card-text>
            <div class="m-2">
              <!-- Table Top -->
              <b-row>
                <!-- Per Page -->
                <b-col
                  cols="12"
                  md="4"
                  class="d-flex align-items-center justify-content-start mb-1 mb-md-0 pull-left"
                  style="margin-left: 0px; padding-left: 0px"
                >
                  <label>Afficher</label>
                  <v-select
                    v-model="perPage"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="perPageOptions"
                    :clearable="false"
                    class="per-page-selector d-inline-block mx-50"
                  />
                  <label>Elements par page</label>
                </b-col>

                <!-- Filters -->

                <list-data-table-filters
                  :status-filter.sync="statusFilter"
                  :status-options="statusOptions"
                />

                <!-- Search -->
                <b-col cols="12" md="4">
                  <div
                    class="d-flex align-items-center justify-content-end pull-right"
                  >
                    <b-form-input
                      v-model="searchQuery"
                      class="d-inline-block mr-1"
                      placeholder="Rechercher..."
                    />
                    <b-button variant="primary" :to="{ name: 'agent-add-new' }">
                      <!-- @click="isAddNewDataSidebarActive = true" -->
                      <span class="text-nowrap">Ajouter Agent</span>
                    </b-button>
                  </div>
                </b-col>
              </b-row>
            </div>

            <b-table
              ref="refDataListTable"
              class="position-relative"
              :items="fetchModuleAgents"
              responsive
              :fields="tableColumns"
              primary-key="id"
              :sort-by.sync="sortBy"
              show-empty
              empty-text="Aucun resultat trouvé"
              :sort-desc.sync="isSortDirDesc"
            >
              <!-- Column: Status -->
              <template #cell(status)="data">
                <b-badge
                  pill
                  :variant="`light-${resolveDataStatusVariant(
                    data.item.status
                  )}`"
                  class="text-capitalize"
                >
                  {{ data.item.status }}
                </b-badge>
              </template>

              <!-- Column: Actions -->
              <template #cell(actions)="data">
                <div class="text-nowrap">
                  <!-- <feather-icon
              :id="`agent-row-${data.item.id}-send-icon`"
              icon="SendIcon"
              class="cursor-pointer"
              size="16"
            />
            <b-tooltip
              title="Send agent"
              class="cursor-pointer"
              :target="`agent-row-${data.item.id}-send-icon`"
            /> -->

                  <feather-icon
                    :id="`agent-row-${data.item.id}-preview-icon`"
                    icon="EyeIcon"
                    size="16"
                    class="mx-1"
                    @click="
                      $router.push({
                        name: 'agent-view',
                        params: { id: data.item.id },
                      })
                    "
                  />
                  <b-tooltip
                    title="Visionner agent"
                    :target="`agent-row-${data.item.id}-preview-icon`"
                  />

                  <b-dropdown
                    variant="link"
                    no-caret
                    :right="$store.state.appConfig.isRTL"
                  >
                    <template #button-content>
                      <feather-icon
                        icon="MoreVerticalIcon"
                        size="16"
                        class="align-middle text-body"
                      />
                    </template>
                    <b-dropdown-item
                      :to="{
                        name: 'agent-view',
                        params: { id: data.item.id },
                      }"
                    >
                      <feather-icon icon="FileTextIcon" />
                      <span class="align-middle ml-50">Détails</span>
                    </b-dropdown-item>

                    <b-dropdown-item
                      :to="{
                        name: 'agent-edit',
                        params: { id: data.item.id },
                      }"
                    >
                      <feather-icon icon="EditIcon" />
                      <span class="align-middle ml-50">Modifier</span>
                    </b-dropdown-item>

                    <b-dropdown-item
                      @click="onDeleteQuestion(data.item)"
                      @refetch-data="refetchData"
                    >
                      <feather-icon icon="TrashIcon" />
                      <span class="align-middle ml-50">Supprimer</span>
                    </b-dropdown-item>
                  </b-dropdown>
                </div>
              </template>
            </b-table>
            <div class="mx-2 mb-2">
              <b-row>
                <b-col
                  cols="12"
                  sm="6"
                  class="d-flex align-items-center justify-content-center justify-content-sm-start"
                >
                  <span class="text-muted"
                    >Affiché {{ dataMeta.from }} à {{ dataMeta.to }} sur
                    {{ dataMeta.of }} Enregistements</span
                  >
                </b-col>
                <!-- Pagination -->
                <b-col
                  cols="12"
                  sm="6"
                  class="d-flex align-items-center justify-content-center justify-content-sm-end"
                >
                  <b-pagination
                    v-model="currentPage"
                    :total-rows="totalDatas"
                    :per-page="perPage"
                    first-number
                    last-number
                    class="mb-0 mt-1 mt-sm-0"
                    prev-class="prev-item"
                    next-class="next-item"
                  >
                    <template #prev-text>
                      <feather-icon icon="ChevronLeftIcon" size="18" />
                    </template>
                    <template #next-text>
                      <feather-icon icon="ChevronRightIcon" size="18" />
                    </template>
                  </b-pagination>
                </b-col>
              </b-row>
            </div>
          </b-card-text>
        </b-tab>
        <b-tab disabled>
          <template #title>
            <feather-icon icon="BarChartIcon" />
            <span>Historique de Travail</span>
          </template>
          <b-card-text>
            Biscuit macaroon sugar plum sesame snaps oat cake halvah fruitcake
            pudding cotton candy. Cheesecake tart wafer soufflé. Chocolate
            marzipan donut pie soufflé dragée cheesecake. Gummi bears dessert
            croissant chocolate jujubes fruitcake. Pie cupcake halvah.
          </b-card-text>
          <b-card-text
            >Tiramisu carrot cake marzipan sugar plum powder marzipan sugar plum
            bonbon powder. Macaroon jujubes ice cream sugar plum lollipop wafer
            caramels. Cheesecake chocolate tart cake gingerbread fruitcake cake
            candy jelly-o. Candy cookie lollipop. Wafer lemon drops chocolate
            cake gummi bears.</b-card-text
          >
        </b-tab>
        <b-tab disabled>
          <template #title>
            <feather-icon icon="BookIcon" />
            <span>Education</span>
          </template>
          <b-card-text>
            Biscuit macaroon sugar plum sesame snaps oat cake halvah fruitcake
            pudding cotton candy. Cheesecake tart wafer soufflé. Chocolate
            marzipan donut pie soufflé dragée cheesecake. Gummi bears dessert
            croissant chocolate jujubes fruitcake. Pie cupcake halvah.
          </b-card-text>
          <b-card-text
            >Tiramisu carrot cake marzipan sugar plum powder marzipan sugar plum
            bonbon powder. Macaroon jujubes ice cream sugar plum lollipop wafer
            caramels. Cheesecake chocolate tart cake gingerbread fruitcake cake
            candy jelly-o. Candy cookie lollipop. Wafer lemon drops chocolate
            cake gummi bears.</b-card-text
          >
        </b-tab>
        <!-- <b-tab title="Disabled" disabled>
          <b-card-text> Carrot cake dragée chocolate. </b-card-text>
        </b-tab> -->
        <b-tab disabled>
          <template #title>
            <feather-icon icon="ListIcon" />
            <span>Contacts</span>
          </template>
          <b-card-text>
            Chocolate croissant cupcake croissant jelly donut. Cheesecake toffee
            apple pie chocolate bar biscuit tart croissant. Lemon drops danish
            cookie. Oat cake macaroon icing tart lollipop cookie sweet bear
            claw.
          </b-card-text>
          <b-card-text>
            Carrot cake dragée chocolate. Lemon drops ice cream wafer gummies
            dragée. Chocolate bar liquorice cheesecake cookie chupa chups
            marshmallow oat cake biscuit. Dessert toffee fruitcake ice cream
            powder tootsie roll cake.
          </b-card-text>
        </b-tab>
      </b-tabs>

      <template #code>
        {{ codeDefault }}
      </template>
    </b-card>

    <!-- :plan-filter.sync="planFilter" -->

    <!-- Table Container Card -->
    <b-card no-body class="mb-0"> </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BTooltip,
  BDropdown,
  BDropdownItem,
  BPagination,
} from "bootstrap-vue";
import vSelect from "vue-select";
import store from "@/store";
import { ref, onUnmounted } from "@vue/composition-api";
import { avatarText } from "@core/utils/filter";
import useModuleList from "./useList";
import storeModule from "./storeModule";
// import ListDataTable from "./ListDataTable.vue";
import ListDataTableFilters from "./ListDataTableFilters.vue";

// sweet alert
import Ripple from "vue-ripple-directive";
import { codeBasic } from "../../extensions/sweet-alert/code";
//
// alerts
import SweetAlertBasic from "../../extensions/sweet-alert/SweetAlertBasic.vue";
import SweetAlertPosition from "../../extensions/sweet-alert/SweetAlertPosition.vue";
import SweetAlertAnimation from "../../extensions/sweet-alert/SweetAlertAnimation.vue";
import SweetAlertTypes from "../../extensions/sweet-alert/SweetAlertTypes.vue";
import SweetAlertOption from "../../extensions/sweet-alert/SweetAlertOption.vue";
import SweetAlertConfirmOption from "../../extensions/sweet-alert/SweetAlertConfirmOption.vue";
// notifications
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { codeIcon } from "../../extensions/toastification/code";
import ToastificationVariant from "../../extensions/toastification/ToastificationVariant.vue";
import ToastificationPosition from "../../extensions/toastification/ToastificationPosition.vue";
import ToastificationIcon from "../../extensions/toastification/ToastificationIcon.vue";
import ToastificationTimeout from "../../extensions/toastification/ToastificationTimeout.vue";

// tabs
import TabDefault from "./TabDefault.vue";
import BCardCode from "@core/components/b-card-code";
import { BTabs, BTab, BCardText } from "bootstrap-vue";
import { codeDefault } from "./code";

export default {
  components: {
    ListDataTableFilters,
    // ListDataTable,

    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BTooltip,
    BDropdown,
    BDropdownItem,
    BPagination,
    vSelect,
    // alerts
    SweetAlertBasic,
    SweetAlertPosition,
    SweetAlertAnimation,
    SweetAlertTypes,
    SweetAlertOption,
    SweetAlertConfirmOption,
    // notification
    ToastificationVariant,
    ToastificationPosition,
    ToastificationIcon,
    ToastificationTimeout,
    //tabs
    TabDefault,
    BCardCode,
    BTabs,
    BCardText,
    BTab,
  },
  data() {
    return {
      codeIcon,
      codeDefault,
    };
  },
  directives: {
    Ripple,
  },
  props: {},
  methods: {
    onDeleteQuestion(agent) {
      this.$swal({
        title: "Action Irreversible",
        text: "Voulez-vous vraiment supprimer ?",
        icon: "question",
        timer: 10000,
        showCancelButton: true,
        confirmButtonText: "Oui [Confirmer Suppression]",
        cancelButtonText: "Annuler",
        customClass: {
          confirmButton: "btn btn-success",
          cancelButton: "btn btn-outline-primary ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          // yes
          this.onDelete(agent);
        } else if (result.dismiss === "cancel") {
          // stay here
        }
      });
    },
  },
  setup() {
    const APP_STORE_MODULE_NAME = "app-agent";

    // Register module
    if (!store.hasModule(APP_STORE_MODULE_NAME))
      store.registerModule(APP_STORE_MODULE_NAME, storeModule);

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(APP_STORE_MODULE_NAME))
        store.unregisterModule(APP_STORE_MODULE_NAME);
    });

    const onDelete = (agent) => {
      store
        .dispatch(APP_STORE_MODULE_NAME + "/deleteModule", agent.id)
        .then(() => {
          // show confirmation notification
          refetchData();
        });
    };

    const isAddNewDataSidebarActive = ref(false);

    const statusOptions = [
      { label: "En Activité", value: "active" },
      { label: "Inactif", value: "inactive" },
      { label: "Non-Spécifié", value: "pending" },
    ];

    const resolveAgentVariant = (direction) => {
      if (direction === "in") return "primary";
      if (direction === "out") return "warning";
      return "primary";
    };

    const resolveAgentIcon = (direction) => {
      if (direction === "in") return "ArrowDownIcon";
      if (direction === "out") return "ArrowUpIcon";
      return "UserIcon";
    };

    const {
      fetchModuleAgents,
      tableColumns,
      perPage,
      currentPage,
      totalDatas,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refDataListTable,
      refetchData,

      // UI
      resolveDataTypeVariant,
      resolveDataTypeIcon,
      resolveDataStatusVariant,

      // Extra Filters
      statusFilter,
    } = useModuleList();

    return {
      // Sidebar
      isAddNewDataSidebarActive,

      fetchModuleAgents,
      tableColumns,
      perPage,
      currentPage,
      totalDatas,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refDataListTable,
      refetchData,

      onDelete,

      // Filter
      avatarText,

      // UI
      resolveAgentIcon,
      resolveAgentVariant,
      resolveDataTypeVariant,
      resolveDataTypeIcon,
      resolveDataStatusVariant,

      statusOptions,

      // Extra Filters
      statusFilter,
    };
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
